@import url('https://fonts.googleapis.com/css2?family=Anuphan:wght@300;400;500;600&display=swap');
*{
    padding:0;
    margin:0;
    font-family: 'Anuphan', sans-serif;
    box-sizing: border-box  ;
}



/* <img src={`${process.env.PUBLIC_URL}/images/logo.png`} /> background-size: cover; */